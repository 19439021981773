export const BASE_URL = {
    API: process.env.REACT_APP_BASE_API,
    EMAIL: process.env.REACT_APP_DEBOUNCE_API,
    CDN: process.env.REACT_APP_CDN_URL,
    CDN_WARD_AVATAR: `${process.env.REACT_APP_BLOB_URL}/avatars/Children/avatar-urls.json`,
    CDN_AVATAR: `${process.env.REACT_APP_BLOB_URL}/avatars/Adults/avatar-urls.json`,
    VIMEO_URL:"https://player.vimeo.com/video/",
    YT_URL:"https://youtube.com/embed/",
    WT_URL : "https://wa.me/",
    FMP_CLOUD : "https://fmpcloud.io/image-stock/",
    CDN_INVESTMENTS : `${process.env.REACT_APP_CDN_URL}/logos/Investments/`
};
